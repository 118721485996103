import Toastr from "../../itrust_common/components/Toastr"

const DEFAULT_STATE = {
  demo_requests: [],
  demo_request: {},
  meta: {pagination: {}},
  saving: false
}
let changes=null
export default function demoRequestReducer(state=DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let demoRequests = [];
  let demoRequestData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'DEMO_REQUESTS_LOAD_PENDING':
    case 'DEMO_REQUEST_CREATE_PENDING':
      return {...state, saving: true};

    case 'DEMO_REQUESTS_LOAD_REJECTED':
    case 'DEMO_REQUEST_CREATE_REJECTED':
    case 'DEMO_REQUEST_LOAD_REJECTED':
    case 'DEMO_REQUEST_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};
    case 'DEMO_REQUESTS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      }
      return {...state, ...changes}
    
    case 'DEMO_REQUEST_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      demoRequests = [...state.demo_requests];
      updatedRecord = response.demo_request;
      for (const [index, demo_request] of demoRequests.entries()) {
        if (demo_request.id === updatedRecord.id) {
          demoRequestData = [
            ...demoRequests.slice(0, index),
            updatedRecord,
            ...demoRequests.slice(index + 1)
          ];
        }
      }
      changes = {
        demo_requests: demoRequestData,
        editing: false
      };
      return { ...state, ...changes };

      case 'DEMO_REQUEST_DELETE_FULFILLED':
        Toastr.handleShow(true, response.meta.message, 201)
        demoRequests = [...state.demo_requests];
        for (const [index, demo_request] of demoRequests.entries()) {
          if (demo_request.id === response.demo_request.id) {
            demoRequests.splice(index, 1);
          }
        }
        return {
          ...state,
          demo_requests: demoRequests,
        };

    case 'DEMO_REQUEST_CREATE_FULFILLED':
      debugger
      changes = {
        // ...response,
        saving: false
      };
      return { ...state, ...changes };

    case 'DEMO_REQUEST_LOAD_FULFILLED':
      changes = {
        demo_request:  {...response.demo_request},
      };
      return { ...state, ...changes };
    case 'DEMO_REQUEST_EDIT':
      changes = {
        editing: action.payload
      };
      return { ...state, ...changes };

    case 'DEMO_REQUEST_EDIT_CANCEL':
      changes = {
        editing: false,
        demo_request: {}
      };
      return { ...state, ...changes };

    default: 
      return state   
  }
}