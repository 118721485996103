import { isDatePast, isValidString } from "../HelperFunctions";

export const required = value => (value && !(value === "+1" || value === "Invalid date" || !isValidString(value)) || value === 0 ? undefined : 'Required');
export const phoneRequired = value => (value && !(value.length < 7 || value.length === 7 || !isValidString(value)) || value === 0 ? undefined : 'Required/Enter valid Number');
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength15 = maxLength(15);
export const maxLength13 = maxLength(13);
export const maxLength3 = maxLength(3);
export const maxLength2 = maxLength(2);
export const maxLength160 = maxLength(160);
export const maxLength60 = maxLength(60);
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength6 = minLength(6);
export const minLength12 = minLength(12);
export const onlyLength = length => value =>
  value && value.length !== length ? `Must be ${length} characters only` : undefined;
export const onlyLength6 = onlyLength(6);
export const onlyLength16 = onlyLength(16);
export const onlyLength3 = onlyLength(3);
export const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const minValue18 = minValue(18);
export const minValue1 = minValue(1);
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
export const onlyLetters = value =>
  value && !/^[A-Za-z ]+$/.test(value)
    ? 'Only letters are allowed'
    : undefined;
export const onlyPositiveNumbers = value =>
  value && !/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(value)
    ? 'Only Positive Numbers are allowed'
    : undefined;
export const onlyNumbers = value =>
  value && !/^[0-9/\s-/ ]+$/.test(value)
    ? 'Only Numbers are allowed'
    : undefined;
export const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined;
export const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? 'Really? You still use AOL for your email?'
    : undefined;
export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;
export const usZipCode = value =>
  value && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)
    ? 'Enter Zip code in the correct format  ##### or #####-####'
    : undefined;

export const pastDate = date => isDatePast(date) ? "Date can't be in Past" : undefined;

export const signedNumber = value =>
  (value && !isNaN(value) && value !== " ") && !/^[+-]\d+(\.\d+)?$/.test(value.trim())
    ? 'Enter a valid number that starts with + or -'
    : undefined;

export const twoDecimalPlaces = value =>
  (value && !isNaN(value) && value !==" " )&& !/^[+-]?\d+(\.\d{2})?$/.test(value)
    ? 'Enter a valid number with up to two decimal places'
    : undefined;

export const alphanumericOnly = value =>
  /^[a-zA-Z0-9]+$/.test(value) ? undefined : 'Only alphanumeric characters allowed';

// export const date = value => {
//   value && moment(value).isValid() ? undefined : 'Invalid date';
// };
