import React, { Component } from 'react';
import Moment from 'moment';
import moment from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import { dateFormat, maskDateInput, parseDateFormats } from '../HelperFunctions'
import Toastr from '../Toastr';
import useOrgDateFormat from '../others/helperHooks/useOrgDateFormat';

Moment.locale('en');
momentLocalizer();
const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const DatePicker = (props) => {
  const { input: { onChange, value, open }, disabled, onChangeHook, wrapperClasses, label, timeSlotDuration, readOnly, meta: { touched, error, warning } } = props;
  const dateFormatType = useOrgDateFormat()

  const handleValue = (value) => {
    return value.length > 20 ? new Date(value) : new Date(value.replace(/-/g, '\/'))
  }

  const handleKeyUp = (e) => {
    let enteredDate = e.target.value
    maskDateInput(e)
    if (dateFormatType == 'DD/MM/YYYY' && enteredDate.length === 10) {
      enteredDate = parseDateFormats(enteredDate, 'DD/MM/YYYY', 'MM/DD/YYYY')
    }
    if (enteredDate === undefined || (enteredDate.length === 10 && new Date(enteredDate) == 'Invalid Date')) {
      Toastr.handleShow(false, 'Enter a valid date')
      onChange('');
      if (onChangeHook) {
        onChangeHook('');
      }
    }
  }

  return (
    <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
      {label !== undefined && <label>{props.label}</label>}
      <DateTimePicker
        {...props}
        onChange={val => {
          val = val ? Moment(val).format("YYYY/MM/DD") : '';
          onChange(val);
          if (onChangeHook) {
            onChangeHook(val);
          }
        }}
        onKeyDown={e => e.keyCode === 191 && e.preventDefault()}
        onKeyUp={e => handleKeyUp(e)}
        onFocus={open}
        time={false}
        value={(!value || value === "Invalid date") ? null : handleValue(value)}
        format={dateFormatType}
        editFormat={dateFormatType}
        placeholder={dateFormatType}
        step={timeSlotDuration}
        readOnly={readOnly}
      />
      {touched &&
        ((error && <span className="error-msg text-danger">{error}</span>) ||
          (warning && <span>{warning}</span>))
      }
      {touched && (error && <i className={`la la-warning text-danger ${label === undefined ? 'error-icon' : 'error-icon-label'}`}></i>)}
    </div>
  )
}

let formatter = (date, dateFormatType) => date ? moment.tz(date, localTimeZone).format(`${dateFormatType} hh:mm A z`) : `${dateFormatType} hh:mm A`;

export const DateAndTimePicker = (props) => {
  const { input: { onChange, value, open }, disabled, onChangeHook, wrapperClasses, label, timeSlotDuration, readOnly, meta: { touched, error, warning } } = props
  const dateFormatType = useOrgDateFormat()

  return (
    <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
      {label !== undefined && <label>{props.label}</label>}
      <DateTimePicker
        {...props}
        onChange={val => {
          onChange(val);
          if (onChangeHook) {
            onChangeHook(val);
          }
        }}
        onKeyDown={e => e.keyCode === 191 && e.preventDefault()}
        onKeyUp={e => maskDateInput(e)}
        onFocus={open}
        value={(!value || value === "Invalid date") ? null : new Date(value)}
        format={(date) => formatter(date, dateFormatType)}
        editFormat={(date) => formatter(date, dateFormatType)}
        placeholder={(date) => formatter(date, dateFormatType)}
        step={timeSlotDuration}
        readOnly={readOnly}
      />
      {touched &&
        ((error && <span className="invalid-feedback text-danger d-inline la-sm">{error}</span>) ||
          (warning && <span>{warning}</span>))
      }
    </div>
  )
}

export const DatePickerIcon = (props) => {
  return (
    <div>
      <DateTimePicker {...props} time={false} />
    </div>
  )
}